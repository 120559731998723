import { AllContentRoles } from "@/constants/userRoles";

export default [
  {
    path: "/content/dashboard",
    component: () =>
      import(/* webpackChunkName: 'ContentDashboard' */ "@/views/content/ContentDashboard.vue"),
    name: "ContentDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/upload-new-questions-pdf",
    component: () =>
      import(
        /* webpackChunkName: 'UploadNewQuestionsPdf' */ "@/views/content/questionBank/UploadNewQuestionsPdf.vue"
      ),
    name: "UploadNewQuestionsPdf",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/verify-new-questions-pdf/:pdfUUID?",
    component: () =>
      import(
        /* webpackChunkName: 'VerifyNewQuestionsPdf' */ "@/views/content/questionBank/VerifyNewQuestionsPdf.vue"
      ),
    name: "VerifyNewQuestionsPdf",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/review-new-question-images/:pdfUUID?",
    component: () =>
      import(
        /* webpackChunkName: 'ReviewNewQuestionImages' */ "@/views/content/questionBank/ReviewNewQuestionImages.vue"
      ),
    name: "ReviewNewQuestionImages",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/tag-new-questions/:pdfUUID?",
    component: () =>
      import(
        /* webpackChunkName: 'TagNewQuestions' */ "@/views/content/questionBank/TagNewQuestions.vue"
      ),
    name: "TagNewQuestions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/review-tagged-questions/:pdfUUID?",
    component: () =>
      import(
        /* webpackChunkName: 'ReviewTaggedQuestions' */ "@/views/content/questionBank/ReviewTaggedQuestions.vue"
      ),
    name: "ReviewTaggedQuestions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/manage-question-pdfs",
    component: () =>
      import(
        /* webpackChunkName: 'ManageQuestionPdfs' */ "@/views/content/questionBank/ManageQuestionPdfs.vue"
      ),
    name: "ManageQuestionPdfs",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/fix-question",
    component: () =>
      import(/* webpackChunkName: 'FixQuestion' */ "@/views/content/FixQuestion.vue"),
    name: "FixQuestion",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/review-course-content",
    component: () =>
      import(
        /* webpackChunkName: 'ReviewCourseContent' */ "@/views/content/ReviewCourseContent.vue"
      ),
    name: "ReviewCourseContent",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/teaching-content-editor",
    component: () =>
      import(
        /* webpackChunkName: 'TeachingContentEditor' */ "@/views/content/TeachingContentEditor.vue"
      ),
    name: "TeachingContentEditor",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/create-preloaded-content",
    component: () =>
      import(
        /* webpackChunkName: 'CreatePreloadedContent' */ "@/views/content/CreatePreloadedContent.vue"
      ),
    name: "CreatePreloadedContent",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
      showAffiliationSelector: true,
    },
  },
  {
    path: "/content/ai-messenger",
    component: () =>
      import(/* webpackChunkName: 'AiMessenger' */ "@/views/content/AiMessenger.vue"),
    name: "AiMessenger",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/course-chapter-mapping",
    component: () =>
      import(
        /* webpackChunkName: 'CourseChapterMapping' */ "@/views/content/CourseChapterMapping.vue"
      ),
    name: "CourseChapterMapping",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/map-ksc-cluster-kscs",
    component: () =>
      import(/* webpackChunkName: 'MapKSCClusterKSCs' */ "@/views/content/MapKSCClusterKSCs.vue"),
    name: "MapKSCClusterKSCs",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/create-ksc-clusters",
    component: () =>
      import(/* webpackChunkName: 'CreateKSCClusters' */ "@/views/content/CreateKSCClusters.vue"),
    name: "CreateKSCClusters",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/qr-codes-generator",
    component: () =>
      import(/* webpackChunkName: 'QrCodesGenerator' */ "@/views/content/QrCodesGenerator.vue"),
    name: "QrCodesGenerator",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/manage-qr-codes",
    component: () =>
      import(/* webpackChunkName: 'ManageQrCodes' */ "@/views/content/ManageQrCodes.vue"),
    name: "ManageQrCodes",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/manage-preloaded-content",
    component: () =>
      import(
        /* webpackChunkName: 'ManagePreloadedContent' */ "@/views/content/ManagePreloadedContent.vue"
      ),
    name: "ManagePreloadedContent",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/chapter-questions-review",
    component: () =>
      import(
        /* webpackChunkName: 'ChapterQuestionsReview' */ "@/views/content/ChapterQuestionsReview.vue"
      ),
    name: "ChapterQuestionsReview",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/course-wise-question-counts",
    component: () =>
      import(
        /* webpackChunkName: 'CourseWiseQuestionCounts' */ "@/views/content/CourseWiseQuestionCounts.vue"
      ),
    name: "CourseWiseQuestionCounts",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
];
