import { handleApiError, handleApiResponse } from "@/methods/api";
import ApiVerified from "@/services/apiVerified";
import {
  ChapterWiseQuestionCounts,
  QuestionByChapterKSC,
  ContentChildrenInputs,
  ContentConfig,
  ContentData,
  ContentIds,
  CreateQrInputs,
  KSCDetails,
  QrCategoryProps,
  QuestionBankQuestion,
  QuestionDetails,
  QuestionPdfExtractedInfo,
  QuestionPdfInfo,
  QuestionPdfPage,
  QuestionPdfSettings,
  QuestionSolutionImage,
  QuestionUpdates,
  StudyMaterial,
  TaggedQuestion,
  QrCodeContentDisplay,
} from "@/types/content";
import { ApiResponse, IdNameChildren, IdNamePair, OrderedIdNameChildren } from "@/types/global";
import { Section, SectionGroup, SelectorQuestion } from "@/types/questionSelector";
import { DisplayConcept, DisplayConceptCluster, LearnVideo } from "@/types/studentDashboard";
export default class ContentApi extends ApiVerified {
  async getInstituteTestRecommendedQuestions(
    testSection: Section,
    exclusionBatchIds: Array<number> | null,
    difficultyLevelId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<SelectorQuestion>>(
        `institute-test/get-recommended-questions`,
        {
          testSection: testSection,
          exclusionBatchIds: exclusionBatchIds,
          difficultyLevelId: difficultyLevelId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionTypesForContent(
    selectedContent: ContentIds,
    categoryId: number | null,
    minimumCount: number | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<number>>(
        `content/get-question-types-for-content`,
        { selectedContent: selectedContent, categoryId: categoryId, minimumCount: minimumCount },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionHtmlUrl(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-html`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionDisplayUrl(
    questionId: number,
    displayType: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-html`, {
        params: { questionId: questionId, displayType: displayType },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionImageUrl(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-image`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSolutionImageUrl(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-solution-image`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionConceptImages(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<string>>(
        `content/get-question-concept-images`,
        {
          params: { questionId: questionId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionSolutionImage(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<string>(`content/get-question-solution-image`, {
        params: { questionId: questionId },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionDetailsByCode(questionCode: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<QuestionDetails>(
        `content/get-question-details-by-code`,
        {
          params: { questionCode: questionCode },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionCountsForContent(selectedContent: ContentIds) {
    try {
      const response = await this.axiosInstance.post(`content/get-question-counts-for-content`, {
        selectedContent: selectedContent,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveAssignmentQuestions(
    assignmentId: number,
    affiliationId: number,
    sectionGroups: Array<SectionGroup>,
    finalizeAssignment: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `assignment/save-assignment-questions`,
        {
          assignmentId: assignmentId,
          affiliationId: affiliationId,
          sectionGroups: sectionGroups,
          finalizeAssignment: finalizeAssignment,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAvailableAssignmentQuestionsForChapter(
    courseChapterId: number,
    kscClusterIds: Array<number> | null,
    questionTypeId: number,
    onlyActive: boolean,
    exclusionBatchIdsCsv: string | null,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<SelectorQuestion>>(
        `assignment/get-available-questions-for-chapter`,
        {
          courseChapterId: courseChapterId,
          kscClusterIds: kscClusterIds,
          questionTypeId: questionTypeId,
          onlyActive: onlyActive,
          exclusionBatchIdsCsv: exclusionBatchIdsCsv,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAssignmentRecommendedQuestions(
    assignmentSection: Section,
    exclusionBatchIds: Array<number> | null,
    difficultyLevelId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<SelectorQuestion>>(
        `assignment/get-recommended-questions`,
        {
          assignmentSection: assignmentSection,
          exclusionBatchIds: exclusionBatchIds,
          difficultyLevelId: difficultyLevelId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateQuestionSolution(questionUpdates: QuestionUpdates): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(`content/update-question-solution`, {
        questionUpdates: questionUpdates,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async executeQuestionHtmlApi(questionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/execute-question-html-api`, {
        questionId: questionId,
        runInContainer: true,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async uploadNewQuestionsPdf(
    pdfFileName: string,
    pdfContent: string,
    pdfSettings: QuestionPdfSettings,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/upload-new-questions-pdf`, {
        pdfFileName: pdfFileName,
        pdfContent: pdfContent,
        pdfSettings: pdfSettings,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionsPdfInfo(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<QuestionPdfExtractedInfo>(
        `content/get-questions-pdf-info`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQuestionsPdfInfo(questionPdfInfo: QuestionPdfExtractedInfo): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/save-questions-pdf-info`, {
        questionPdfInfo: questionPdfInfo,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async verifyNewQuestionsPdf(questionPdfInfo: QuestionPdfExtractedInfo): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`content/verify-new-questions-pdf`, {
        questionPdfInfo: questionPdfInfo,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionsPdfImages(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionSolutionImage>>(
        `content/get-questions-pdf-images`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQuestionSolutionImages(
    pdfUUID: string,
    questionSolutionImages: Array<QuestionSolutionImage>,
    saveQuestionBank: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(
        `content/save-question-solution-images`,
        {
          pdfUUID: pdfUUID,
          questionSolutionImages: questionSolutionImages,
          saveQuestionBank: saveQuestionBank,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQuestionsFromQuestionBank(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionBankQuestion>>(
        `content/get-questions-from-question-bank`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQuestionBankTags(questionTags: Array<QuestionBankQuestion>): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(`content/save-question-bank-tags`, {
        questionTags: questionTags,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async submitQuestionBankForProcessing(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `content/submit-question-bank-for-processing`,
        { pdfUUID: pdfUUID },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async processQuestionBankQuestions(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `content/process-question-bank-questions`,
        { pdfUUID: pdfUUID },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTaggedQuestionsForReview(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TaggedQuestion>(
        `content/get-tagged-questions-for-review`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQuestionBankContentTag(
    questionBankId: number,
    courseChapterId: number,
    kscClusterId: number,
    kscId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `content/save-question-bank-content-tag`,
        {
          questionBankId: questionBankId,
          courseChapterId: courseChapterId,
          kscClusterId: kscClusterId,
          kscId: kscId,
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveReviewedQuestionBankQuestions(
    taggedQuestions: Array<TaggedQuestion>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(
        `content/save-reviewed-question-bank-questions`,
        taggedQuestions,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async loadAllQuestionPdfs(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionPdfInfo>>(
        `content/get-all-question-pdfs`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPdfPagesForPdfUUID(pdfUUID: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionPdfPage>>(
        `content/get-pdf-pages-for-pdf-uuid`,
        { params: { pdfUUID: pdfUUID } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getAllCourseChapters(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `content/get-all-course-chapters`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseChaptersForBatch(
    batchId: number,
    subjectId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-chapters-for-batch`,
        {
          params: { batchId: batchId, subjectId: subjectId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSelectedContent(
    courseChapterIds: Array<number>,
    kscClusterIds: Array<number> | null,
    contentConfig: Array<ContentConfig>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<ContentIds>(`content/selected-content`, {
        courseChapterIds: courseChapterIds,
        kscClusterIds: kscClusterIds,
        contentConfig: contentConfig,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getKSCClustersForCourseChapter(
    courseChapterId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `content/get-ksc-clusters-for-course-chapter`,
        {
          params: { courseChapterId: courseChapterId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getKSCsForKSCCluster(kscClusterId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `content/get-kscs-for-ksc-cluster`,
        {
          params: { kscClusterId: kscClusterId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getNestedKSCsForChapter(
    courseChapterId: number,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNameChildren>>(
        `content/nested-kscs-for-chapter`,
        {
          params: { courseChapterId: courseChapterId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getKSCDetailsByCode(kscCode: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<KSCDetails>(`content/get-ksc-details-by-code`, {
        params: { kscCode: kscCode },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveKSCDetails(kscDetails: KSCDetails): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `content/save-ksc-details`,
        kscDetails,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCommonChaptersForBatches(batchIdsCsv: string, subjectId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-common-chapters`,
        {
          params: { batchIdsCsv: batchIdsCsv, subjectId: subjectId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseChaptersMapping(
    courseId: number,
    includeTopics: boolean,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNameChildren>>(
        `content/get-course-chapters-mapping`,
        {
          params: { courseId: courseId, includeTopics: includeTopics, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getConceptClustersForChapter(
    userId: number,
    courseChapterId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<DisplayConceptCluster>>(
        `/learn/get-concept-clusters`,
        {
          params: { userId: userId, courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getConceptsForConceptCluster(
    courseChapterId: number,
    kscClusterId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<DisplayConcept>>(`/learn/get-concepts`, {
        params: {
          courseChapterId: courseChapterId,
          kscClusterId: kscClusterId,
        },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLearnVideosForChapter(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<LearnVideo>>(
        `/learn/get-learn-videos-for-student`,
        {
          params: { courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getContentNames(contentIds: Array<number>, contentType: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<IdNamePair>>(`content/content-names`, {
        contentIds: contentIds,
        contentType: contentType,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getContentChildren(contentChildrenInputs: ContentChildrenInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<ContentData>>(
        `content/content-children`,
        contentChildrenInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getNestedContentChildren(
    contentChildrenInputs: ContentChildrenInputs,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<Array<ContentData>>(
        `content/nested-content-children`,
        contentChildrenInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCoursesForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`content/get-courses`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getClassesForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`content/get-classes`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSubjectsForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`content/get-subjects`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTopicsForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`content/get-topics`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getChaptersForContent(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`content/get-chapters`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSubjectsForStudent(userId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `content/get-subjects-for-student`,
        {
          params: { userId: userId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveCourseChapterMapping(courseChapters: OrderedIdNameChildren): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `content/save-course-chapter-mapping`,
        { courseChapters: courseChapters },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveKSCClustersForCourseChapter(
    courseChapterId: number,
    kscClusters: Array<OrderedIdNameChildren>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `content/save-ksc-clusters-for-course-chapter`,
        { courseChapterId: courseChapterId, kscClusters: kscClusters },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createNewPdfUUID(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<string>(`content/create-new-pdf-uuid`, {
        courseId: courseId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourses(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`content/get-courses`, {
        params: { onlyActive: onlyActive },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseSubjects(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `content/get-course-subjects`,
        {
          params: { courseId: courseId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getSubjectsForBatch(batchId: number, onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-subjects-for-batch`,
        {
          params: { batchId: batchId, onlyActive: onlyActive },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCommonSubjectsForBatches(batchIdsCsv: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-common-subjects`,
        {
          params: { batchIdsCsv: batchIdsCsv, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCoursesForAffiliation(affiliationId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `affiliation-batch/get-affiliation-courses`,
        {
          params: { affiliationId: affiliationId, onlyActive: true },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getClassesForStudent(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `content/get-classes-for-student`,
        {
          params: { userId: userId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudyMaterialForStudent(userId: number, courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<StudyMaterial>>(
        `/learn/get-study-material-for-student`,
        {
          params: {
            userId: userId,
            courseChapterId: courseChapterId,
          },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQrCategoryProps(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<{ [categoryId: number]: QrCategoryProps }>(
        `content/get-qr-category-props`,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async createQrCode(qrCreateInputs: CreateQrInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<CreateQrInputs>(
        `content/create-qr-code`,
        qrCreateInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveQrCodeContent(qrCodeContentInputs: CreateQrInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `content/save-qr-code-content`,
        qrCodeContentInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQrCodeDetails(qrCode: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<QrCodeContentDisplay>(
        `content/get-qr-code-details`,
        {
          params: { qrCode: qrCode },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseWiseQuestionCounts(
    courseId: number,
    classId: number,
    subjectId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<ChapterWiseQuestionCounts>>(
        `content/get-course-wise-question-counts`,
        {
          params: { courseId: courseId, classId: classId, subjectId: subjectId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getChapterQuestionsForReview(courseChapterId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<QuestionByChapterKSC>>(
        `content/get-chapter-questions-for-review`,
        {
          params: { courseChapterId: courseChapterId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getQrCodesForCategory(qrCategoryId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<QrCodeContentDisplay>(
        `content/get-qr-codes-for-category`,
        {
          params: { qrCategoryId: qrCategoryId },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async deleteQrCodeContent(qrCode: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.delete<boolean>(`content/delete-qr-code-content`, {
        params: { qrCode: qrCode },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async runCustomOpenAIPrompt(systemMessage: string, promptBody: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<any>(`/open-ai/run-custom-open-ai-prompt`, {
        params: { systemMessage: systemMessage, promptBody: promptBody },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
