<template>
  <!-- Root div required to keep it separate from App.vue classes -->
  <div>
    <div
      class="page-container bg-white mx-auto my-3 rounded px-lg-4 px-md-3 px-2 px-md-4 py-3"
      :class="addFlexFill ? 'flexcol flex-fill' : ''"
    >
      <div class="flexrow xbetween ycenter mb-3">
        <div class="flexrow xstart ycenter gap-2">
          <div class="color-primary fw-bold fs-m pe-2">{{ pageTitle }}</div>
        </div>
        <div class="flexrow xend ycenter gap-2">
          <slot name="actionButton"></slot>
          <ThePreviousCloseButtons />
        </div>
      </div>
      <slot name="pageContent"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import ThePreviousCloseButtons from "@/components/ThePreviousCloseButtons.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ThePageTitleContainer",
  props: {
    pageTitle: { type: String, required: true },
    addFlexFill: { type: Boolean, default: false },
  },
  components: { ThePreviousCloseButtons },
});
</script>

<style lang="scss" scoped>
.page-container {
  width: 80%;
  @include media("<=xl") {
    width: 90%;
  }
  @include media("<=m") {
    width: 97%;
  }
}
</style>
