import { AllStudentRoles, AllUserRoles } from "@/constants/userRoles";
export default [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "LandingPage" */ "@/views/common/LandingPage.vue"),
    name: "LandingPage",
    meta: {
      requiresUnAuth: true,
      scrollToTop: true,
      hideNavbar: true,
      hideFooter: false,
      allowedRoles: AllUserRoles,
    },
  },
  { path: "/home", redirect: "/" },
  { path: "/register", redirect: "/" },
  { path: "/login", redirect: "/" },
  {
    path: "/change-password",
    component: () =>
      import(/* webpackChunkName: "Unauthorized" */ "@/views/common/ChangePassword.vue"),
    name: "ChangePassword",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/delete-account-permanently",
    component: () =>
      import(
        /* webpackChunkName: 'DeleteAccountPermanently' */ "@/views/common/DeleteAccountPermanently.vue"
      ),
    name: "DeleteAccountPermanently",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/login-redirect/:loginId/:accessToken/:redirectTo?",
    component: () =>
      import(/* webpackChunkName: "LoginRedirect" */ "@/views/common/LoginRedirect.vue"),
    name: "LoginRedirect",
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      hideNavbar: true,
      hideFooter: false,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/logout/:isSystemExpired?",
    component: () => import(/* webpackChunkName: "LandingPage" */ "@/views/common/Logout.vue"),
    name: "Logout",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideNavbar: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/resource-not-found",
    component: () =>
      import(/* webpackChunkName: "ResourceNotFound" */ "@/views/common/ResourceNotFound.vue"),
    name: "ResourceNotFound",
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      hideFooter: false,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/student-assignment-report/:assignmentUserId",
    component: () =>
      import(
        /* webpackChunkName: "StudentAssignmentReport" */ "@/views/common/StudentAssignmentReport.vue"
      ),
    name: "StudentAssignmentReport",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/student-test-report/:testType/:testInstanceId",
    component: () =>
      import(/* webpackChunkName: "StudentTestReport" */ "@/views/common/StudentTestReport.vue"),
    name: "StudentTestReport",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/student-self-practice-report/:selfPracticeId",
    component: () =>
      import(
        /* webpackChunkName: "StudentTestReport" */ "@/views/common/StudentSelfPracticeReport.vue"
      ),
    name: "StudentSelfPracticeReport",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/unauthorized",
    component: () =>
      import(/* webpackChunkName: "Unauthorized" */ "@/views/common/Unauthorized.vue"),
    name: "Unauthorized",
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/under-maintenance",
    component: () =>
      import(/* webpackChunkName: "UnderMaintenance" */ "@/views/common/UnderMaintenance.vue"),
    name: "UnderMaintenance",
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      hideFooter: false,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/too-many-requests",
    component: () =>
      import(/* webpackChunkName: "TooManyRequests" */ "@/views/common/TooManyRequests.vue"),
    name: "TooManyRequests",
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
  {
    path: "/qr-code/:qrCode",
    component: () =>
      import(/* webpackChunkName: 'ViewQrCodeContent' */ "@/views/common/ViewQrCodeContent.vue"),
    name: "ViewQrCodeContent",
    meta: {
      requiresAuth: false,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllUserRoles,
    },
  },
];
