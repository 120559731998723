<template>
  <img
    class="notification-icon"
    :src="getIconUrl(iconName)"
    :style="{ width: `${iconSize}px`, height: `${iconSize}px` }"
  />
</template>

<script lang="ts">
import { getIconUrl } from "@/methods/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotificationIcon",
  props: {
    iconName: { type: String, required: true },
    iconSize: { type: Number, default: 40 },
  },
  setup() {
    return { getIconUrl };
  },
});
</script>
