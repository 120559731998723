import { AllStudentRoles } from "@/constants/userRoles";
export default [
  {
    path: "/student/assignments",
    component: () =>
      import(/* webpackChunkName: 'Assignments' */ "@/views/student/Assignments.vue"),
    name: "Assignments",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/conduct-assignment/:assignmentId",
    component: () =>
      import(/* webpackChunkName: 'ConductAssignment' */ "@/views/student/ConductAssignment.vue"),
    name: "ConductAssignment",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/conduct-test/:testType/:testId",
    component: () =>
      import(/* webpackChunkName: 'ConductTest' */ "@/views/student/ConductTest.vue"),
    name: "ConductTest",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/create-self-test",
    component: () =>
      import(/* webpackChunkName: 'CreateSelfTest' */ "@/views/student/CreateSelfTest.vue"),
    name: "CreateSelfTest",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/doubt-solving",
    component: () =>
      import(/* webpackChunkName: 'DoubtSolving' */ "@/views/student/DoubtSolving.vue"),
    name: "DoubtSolving",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/institute-tests",
    component: () =>
      import(/* webpackChunkName: 'InstituteTests' */ "@/views/student/InstituteTests.vue"),
    name: "InstituteTests",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/learn-dashboard",
    component: () =>
      import(/* webpackChunkName: 'LearnDashboard' */ "@/views/student/LearnDashboard.vue"),
    name: "LearnDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/live-classes",
    component: () =>
      import(/* webpackChunkName: 'LiveClasses' */ "@/views/student/LiveClasses.vue"),
    name: "LiveClasses",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/live-practice",
    component: () =>
      import(/* webpackChunkName: 'LivePractice' */ "@/views/student/LivePractice.vue"),
    name: "LivePractice",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/past-year-papers",
    component: () =>
      import(/* webpackChunkName: 'PastYearPapers' */ "@/views/student/PastYearPapers.vue"),
    name: "PastYearPapers",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/preloaded-tests",
    component: () =>
      import(/* webpackChunkName: 'PreloadedTests' */ "@/views/student/PreloadedTests.vue"),
    name: "PreloadedTests",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/report-effort-analysis",
    component: () =>
      import(
        /* webpackChunkName: 'ReportEffortAnalysis' */ "@/views/student/ReportEffortAnalysis.vue"
      ),
    name: "ReportEffortAnalysis",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/report-subject-coverage",
    component: () =>
      import(
        /* webpackChunkName: 'ReportSubjectCoverage' */ "@/views/student/ReportSubjectCoverage.vue"
      ),
    name: "ReportSubjectCoverage",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/report-test-performance",
    component: () =>
      import(
        /* webpackChunkName: 'ReportTestPerformance' */ "@/views/student/ReportTestPerformance.vue"
      ),
    name: "ReportTestPerformance",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/revision-questions",
    component: () =>
      import(/* webpackChunkName: 'RevisionQuestions' */ "@/views/student/RevisionQuestions.vue"),
    name: "RevisionQuestions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/self-practice",
    component: () =>
      import(/* webpackChunkName: 'SelfPractice' */ "@/views/student/SelfPractice.vue"),
    name: "SelfPractice",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/conduct-self-practice/:selfPracticeId",
    component: () =>
      import(
        /* webpackChunkName: 'ConductSelfPractice' */ "@/views/student/ConductSelfPractice.vue"
      ),
    name: "ConductSelfPractice",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/self-tests",
    component: () => import(/* webpackChunkName: 'SelfTests' */ "@/views/student/SelfTests.vue"),
    name: "SelfTests",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/dashboard",
    component: () =>
      import(/* webpackChunkName: 'StudentDashboard' */ "@/views/student/StudentDashboard.vue"),
    name: "StudentDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/my-profile",
    component: () =>
      import(/* webpackChunkName: 'StudentProfile' */ "@/views/student/StudentProfile.vue"),
    name: "StudentProfile",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/student/subjective-evaluation",
    component: () =>
      import(
        /* webpackChunkName: 'SubjectiveEvaluation' */ "@/views/student/SubjectiveEvaluation.vue"
      ),
    name: "SubjectiveEvaluation",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
];
