import {
  AllTutoringOpsRoles,
  AllTeacherRoles,
  AllStudentRoles,
  AllTutoringAdminRoles,
} from "@/constants/userRoles";

export default [
  {
    path: "/tutoring/admin-add-student-payment",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringAddStudentPayment' */ "@/views/tutoring/TutoringAddStudentPayment.vue"
      ),
    name: "TutoringAddStudentPayment",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/add-student-payment",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringAddStudentPayment' */ "@/views/tutoring/TutoringAddStudentPayment.vue"
      ),
    name: "TutoringAddStudentPayment",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/manage-paid-students",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringManagePaidStudents' */ "@/views/tutoring/TutoringManagePaidStudents.vue"
      ),
    name: "TutoringManagePaidStudents",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/manage-demo-students",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringManageDemoStudents' */ "@/views/tutoring/TutoringManageDemoStudents.vue"
      ),
    name: "TutoringManageDemoStudents",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/admin-dashboard",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringAdminDashboard' */ "@/views/tutoring/TutoringAdminDashboard.vue"
      ),
    name: "TutoringAdminDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/book-demo-session",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringBookDemoSession' */ "@/views/tutoring/TutoringBookDemoSession.vue"
      ),
    name: "TutoringBookDemoSession",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/book-paid-sessions",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringBookPaidSessions' */ "@/views/tutoring/TutoringBookPaidSessions.vue"
      ),
    name: "TutoringBookPaidSessions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/manage-demo-sessions",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringManageDemoSessions' */ "@/views/tutoring/TutoringManageDemoSessions.vue"
      ),
    name: "TutoringManageDemoSessions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/manage-paid-sessions",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringManagePaidSessions' */ "@/views/tutoring/TutoringManagePaidSessions.vue"
      ),
    name: "TutoringManagePaidSessions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/manage-demo-slots",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringManageDemoSlots' */ "@/views/tutoring/TutoringManageDemoSlots.vue"
      ),
    name: "TutoringManageDemoSlots",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTeacherRoles,
    },
  },
  {
    path: "/tutoring/onboard-paid-student",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringOnboardPaidStudent' */ "@/views/tutoring/TutoringOnboardPaidStudent.vue"
      ),
    name: "TutoringOnboardPaidStudent",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
  {
    path: "/tutoring/student-dashboard",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringStudentDashboard' */ "@/views/tutoring/TutoringStudentDashboard.vue"
      ),
    name: "TutoringStudentDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/tutoring/student-study-notes",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringStudentStudyNotes' */ "@/views/tutoring/TutoringStudentStudyNotes.vue"
      ),
    name: "TutoringStudentStudyNotes",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllStudentRoles,
    },
  },
  {
    path: "/tutoring/tutor-dashboard",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringTutorDashboard' */ "@/views/tutoring/TutoringTutorDashboard.vue"
      ),
    name: "TutoringTutorDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTeacherRoles,
    },
  },
  {
    path: "/tutoring/tutor-lesson-plans",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringTutorLessonPlans' */ "@/views/tutoring/TutoringTutorLessonPlans.vue"
      ),
    name: "TutoringTutorLessonPlans",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTeacherRoles.concat(AllTutoringAdminRoles),
    },
  },
  {
    path: "/tutoring/tutor-monthly-report",
    component: () =>
      import(
        /* webpackChunkName: 'TutoringMonthlyReport' */ "@/views/tutoring/TutoringMonthlyReport.vue"
      ),
    name: "TutoringMonthlyReport",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllTutoringOpsRoles,
    },
  },
];
