<template>
  <el-alert
    v-if="showAlertToUser"
    :title="userAlert.messageTitle"
    :type="userAlert.messageType"
    :description="userAlert.messageDescription"
    :effect="userAlert.isDark ? 'dark' : 'light'"
  />
</template>
<script lang="ts">
import { useSessionStore } from "@/stores/sessionStore";
import { UserAlert } from "@/types/global";
import { storeToRefs } from "pinia";
import { PropType, computed, defineComponent } from "vue";

export default defineComponent({
  name: "CustomAlert",
  props: {
    userAlert: { type: Object as PropType<UserAlert>, required: true },
  },
  setup(props) {
    const sessionStore = useSessionStore();
    const { UserInfo } = storeToRefs(sessionStore);
    const showAlertToUser = computed(() => {
      if (!props.userAlert.userRoleIds || props.userAlert.userRoleIds.length === 0) {
        return true;
      }
      return props.userAlert.userRoleIds.includes(UserInfo.value.UserRoleId);
    });
    return { showAlertToUser };
  },
});
</script>
<style lang="scss">
.el-alert {
  --el-alert-border-radius-base: 0;
  & .el-alert__title {
    @include font-size($font-size-xs);
    font-weight: $font-weight-600 !important;
  }
  & .el-alert__content {
    gap: 0 !important;
  }
  & .el-alert__description {
    @include font-size($font-size-xxs);
  }
}
</style>
