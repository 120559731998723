<template>
  <div v-loading.lock="loadingBatchData">
    <CustomTreeSelect
      v-if="batchTreeData"
      :inputId="inputId"
      :treeData="batchTreeData"
      :initialValue="selectedBatches"
      :inputLabel="inputLabel"
      :isMultiSelect="isMultiSelect"
      :isFormField="isFormField"
      :isDisabled="isDisabled"
      :isSmall="isSmall"
      @onChange="onChange"
      @onBlur="onBlur"
    />
    <CustomDropdown
      v-else
      :inputId="inputId"
      :dropdownItems="allBatches"
      :initialValue="selectedBatches"
      :inputLabel="inputLabel"
      :isFormField="isFormField"
      :isFilterable="true"
      :isMultiSelect="isMultiSelect"
      :isDisabled="isDisabled"
      :isSmall="isSmall"
      @onChange="onChange"
      @onBlur="onBlur"
    />
  </div>
</template>

<script lang="ts">
import CustomDropdown from "@/components/input/CustomDropdown.vue";
import CustomTreeSelect from "@/components/input/CustomTreeSelect.vue";
import { convertToTreeData } from "@/methods/utils";
import { useParentAffiliationStore } from "@/stores/parentAffiliationStore";
import { IdNamePair } from "@/types/global";
import { defineComponent, onBeforeMount, ref } from "vue";

export default defineComponent({
  name: "CustomBatchTreeSelect",
  props: {
    inputId: { type: String, default: "BatchIds" },
    initialValue: { type: [Array<number>, Number], default: null },
    inputLabel: { type: String, required: false },
    isMultiSelect: { type: Boolean, default: true },
    isFormField: { type: Boolean, default: false },
    affiliationId: { type: Number, default: null },
    isDisabled: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },
  components: { CustomTreeSelect, CustomDropdown },
  setup(props, { emit }) {
    const parentAffiliationStore = useParentAffiliationStore();
    const { getAffiliationCourseBatchTree } = parentAffiliationStore;
    const batchTreeData = ref();
    const allBatches = ref<Array<IdNamePair>>([]);
    const selectedBatches = ref(props.initialValue);
    const loadingBatchData = ref(false);

    onBeforeMount(async () => {
      loadingBatchData.value = true;
      const treeLevels = ["Courses", "Batches"];
      // If component is used in Parent Affiliation, there are some
      // cases where only a specific child affiliation should be
      // shown - e.g. when using in UpdateUser to change Batch
      let affiliationBatches = await getAffiliationCourseBatchTree(null);
      if (affiliationBatches) {
        if (props.affiliationId !== null) {
          affiliationBatches = affiliationBatches.filter(
            (affiliation) => affiliation.Id === props.affiliationId,
          );
        }
        if (affiliationBatches.length > 1) {
          batchTreeData.value = convertToTreeData(affiliationBatches as Array<object>, treeLevels);
        } else {
          for (const affiliation of affiliationBatches) {
            for (const course of affiliation.Courses) {
              for (const batch of course.Batches) {
                allBatches.value.push({ Id: batch.Id, Name: batch.Name });
              }
            }
          }
        }
        loadingBatchData.value = false;
      }
    });

    function onChange(value: number | Array<number>) {
      selectedBatches.value = value;
      emitEvent("onChange");
    }

    function onBlur() {
      emitEvent("onBlur");
    }

    function emitEvent(eventName: string) {
      if (!props.isMultiSelect && Array.isArray(selectedBatches.value)) {
        emit(eventName, selectedBatches.value[0]);
      } else {
        emit(eventName, selectedBatches.value);
      }
    }
    return { batchTreeData, selectedBatches, allBatches, loadingBatchData, onChange, onBlur };
  },
});
</script>
