<template>
  <div v-if="currentMenuItem && isMenuVisible">
    <el-popover :width="185" trigger="click">
      <template #reference>
        <div class="bi bi-three-dots-vertical fs-l tightest color-white cursor-pointer"></div>
      </template>
      <template #default>
        <div class="px-1">
          <div
            v-if="currentMenuItem.AllowRotate"
            class="popover-item-grid color-info cursor-pointer mb-2"
            @click="onAllowRotateClicked"
          >
            <i class="bi bi-arrow-90deg-right fs-s"></i>
            <div class="fs-xs">Rotate Screen</div>
          </div>
          <div
            v-if="currentMenuItem.EnableCasting"
            class="popover-item-grid color-info cursor-pointer mb-2"
            @click="onEnableCastingClicked"
          >
            <i class="bi bi-cast fs-s"></i>
            <div class="fs-xs">Enable Casting</div>
          </div>
          <div
            v-if="currentMenuItem.ViewDownloads"
            class="popover-item-grid color-info cursor-pointer"
            @click="onViewDownloadsClicked"
          >
            <i class="bi bi-download fs-s"></i>
            <div class="fs-xs">View Downloads</div>
          </div>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<script lang="ts">
import {
  onAllowRotateClicked,
  onEnableCastingClicked,
  onViewDownloadsClicked,
} from "@/methods/utils";
import { useSessionStore } from "@/stores/sessionStore";
import { MenuItem } from "@/types/global";
import { storeToRefs } from "pinia";
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "RouteActionableMenu",
  setup() {
    const sessionStore = useSessionStore();
    const { navbarMenu, IsMobileDevice } = storeToRefs(sessionStore);
    const currentRoute = useRoute();
    const currentMenuItem = ref<MenuItem>();

    watch(currentRoute, () => {
      if (navbarMenu.value === null) {
        return;
      }
      currentMenuItem.value = undefined;
      for (let m = 0; m < navbarMenu.value.MenuItems.length; m++) {
        const menuItem = navbarMenu.value.MenuItems[m];
        if (menuItem.MenuName === currentRoute.name) {
          currentMenuItem.value = menuItem;
          break;
        }
        if (menuItem.ChildItems) {
          for (let c = 0; c < menuItem.ChildItems.length; c++) {
            const childItem = menuItem.ChildItems[c];
            if (childItem.MenuName === currentRoute.name) {
              currentMenuItem.value = childItem;
              break;
            }
          }
        }
        if (currentMenuItem.value !== undefined) {
          break;
        }
      }
    });

    const isMenuVisible = computed(() => {
      return (
        IsMobileDevice &&
        currentMenuItem.value !== undefined &&
        (!!currentMenuItem.value.EnableCasting ||
          !!currentMenuItem.value.AllowRotate ||
          !!currentMenuItem.value.ViewDownloads)
      );
    });

    return {
      isMenuVisible,
      currentMenuItem,
      onAllowRotateClicked,
      onEnableCastingClicked,
      onViewDownloadsClicked,
    };
  },
});
</script>
<style lang="scss" scoped>
.popover-item-grid {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  &:hover {
    color: $color-primary !important;
  }
}
</style>
