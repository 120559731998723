import { AllAEMRoles } from "@/constants/userRoles";
import { AllContentRoles } from "@/constants/userRoles";
export default [
  {
    path: "/aem/dashboard",
    component: () => import(/* webpackChunkName: 'AEMDashboard' */ "@/views/aem/AEMDashboard.vue"),
    name: "AEMDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAEMRoles,
    },
  },
  {
    path: "/aem/manage-omr-uploads",
    component: () =>
      import(/* webpackChunkName: 'ManageOmrUploads' */ "@/views/aem/ManageOmrUploads.vue"),
    name: "ManageOmrUploads",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAEMRoles.concat(AllContentRoles),
    },
  },
  {
    path: "/aem/school-academic-plan",
    component: () =>
      import(/* webpackChunkName: 'SchoolAcademicPlan' */ "@/views/aem/SchoolAcademicPlan.vue"),
    name: "SchoolAcademicPlan",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAEMRoles.concat(AllContentRoles),
    },
  },
  {
    path: "/aem/school-academic-plan-monthly",
    component: () =>
      import(
        /* webpackChunkName: 'SchoolAcademicPlanMonthly' */ "@/views/aem/SchoolAcademicPlanMonthly.vue"
      ),
    name: "SchoolAcademicPlanMonthly",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAEMRoles.concat(AllContentRoles),
    },
  },
  {
    path: "/aem/manage-schools",
    component: () =>
      import(/* webpackChunkName: 'ManageSchools' */ "@/views/aem/ManageSchools.vue"),
    name: "ManageSchools",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAEMRoles.concat(AllContentRoles),
    },
  },
  {
    path: "/aem/finalize-uploaded-omrs/:instituteTestId/:batchId/:omrUploadId",
    component: () =>
      import(/* webpackChunkName: 'FinalizeUploadedOmrs' */ "@/views/aem/FinalizeUploadedOmrs.vue"),
    name: "FinalizeUploadedOmrs",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAEMRoles.concat(AllContentRoles),
    },
  },
];
