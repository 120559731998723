import { handleApiError, handleApiResponse } from "@/methods/api";
import ApiVerified from "@/services/apiVerified";
import { ApiResponse, IdNamePair } from "@/types/global";
import {
  BookPaidSessionsInputs,
  DemoSessionDisplay,
  DemoSlotInfo,
  DemoSlotStatus,
  StudentCourseProgress,
  TutoringBookDemoSessionInputs,
  TutoringModuleLessonPlan,
  TutoringModuleStudyNotes,
  TutoringStudentDetailedDisplay,
  TutoringStudentDisplay,
  TutoringStudentInfo,
  TutoringStudentInputs,
  TutoringStudentPaymentDisplay,
  TutoringStudentPaymentInputs,
  TutorMonthlySalaryDisplay,
  TutorSessionDisplay,
  TutorSessionLog,
  UpdateStudentDataInputs,
} from "@/types/tutoring";

export default class TutorApi extends ApiVerified {
  async getStudentInfoForUserId(userId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TutoringStudentInfo>(
        `tutoring/get-student-info-for-user-id`,
        { params: { userId: userId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTutoringCurrencies(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`tutoring/get-currencies`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTutoringCountries(): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(`tutoring/get-countries`);
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTutoringTimeZonesForCountry(countryId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `tutoring/get-time-zones-for-country`,
        { params: { countryId: countryId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTutoringCourses(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `tutoring/get-tutoring-courses`,
        { params: { onlyActive: onlyActive } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTutoringBatches(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `tutoring/get-tutoring-batches`,
        { params: { onlyActive: onlyActive } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getActiveTutoringUsersByRoleId(roleId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `tutoring/get-active-tutoring-users-by-role-id`,
        { params: { roleId: roleId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async onboardNewStudent(newStudentInputs: TutoringStudentInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `tutoring/onboard-new-student`,
        newStudentInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async addStudentPayment(
    studentPaymentInputs: TutoringStudentPaymentInputs,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `tutoring/add-student-payment`,
        studentPaymentInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async bookDemoSession(bookDemoSession: TutoringBookDemoSessionInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `tutoring/book-demo-session`,
        bookDemoSession,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDetailedPaidStudents(
    minDate: string,
    maxDate: string,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutoringStudentDisplay>>(
        `tutoring/get-detailed-paid-students`,
        { params: { minDate: minDate, maxDate: maxDate, onlyActive: onlyActive } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
  async getDetailedDemoStudents(
    minDate: string,
    maxDate: string,
    onlyActive: boolean,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutoringStudentDisplay>>(
        `tutoring/get-detailed-demo-students`,
        { params: { minDate: minDate, maxDate: maxDate, onlyActive: onlyActive } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTutoringPaidStudents(onlyActive: boolean): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<IdNamePair>>(
        `tutoring/get-paid-students`,
        { params: { onlyActive: onlyActive } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getLessonPlansForTutorCourse(courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutoringModuleLessonPlan>>(
        `tutoring/get-lesson-plans-for-course`,
        { params: { courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudyNotesForStudent(studentId: number, courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutoringModuleStudyNotes>>(
        `tutoring/get-study-notes-for-student`,
        { params: { studentId: studentId, courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async bookPaidSessions(bookPaidSessionsInputs: BookPaidSessionsInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/tutoring/book-paid-sessions`,
        bookPaidSessionsInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUnassignedDemosForTutor(tutorId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<DemoSessionDisplay>>(
        `tutoring/get-unassigned-demo-sessions-for-tutor`,
        { params: { tutorId: tutorId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async acceptUnassignedDemo(demoSessionId: number, tutorId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`tutoring/accept-unassigned-demo`, {
        tutorId: tutorId,
        demoSessionId: demoSessionId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUpcomingSessionsForTutor(tutorId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutorSessionDisplay>>(
        `tutoring/get-upcoming-sessions-for-tutor`,
        { params: { tutorId: tutorId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getUpcomingSessionsForStudent(studentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutorSessionDisplay>>(
        `tutoring/get-upcoming-sessions-for-student`,
        { params: { studentId: studentId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getActiveSessionsForTutor(tutorId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutorSessionDisplay>>(
        `tutoring/get-active-sessions-for-tutor`,
        { params: { tutorId: tutorId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
  async getCompletedSessionsForTutor(
    tutorId: number,
    minDate: string,
    maxDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutorSessionDisplay>>(
        `tutoring/get-completed-sessions-for-tutor`,
        { params: { tutorId: tutorId, minDate: minDate, maxDate: maxDate } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
  async getAllDemoSlots(endDatetime: Date): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<DemoSlotInfo>>(
        `tutoring/get-all-demo-slots`,
        { params: { endDatetime: endDatetime } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDemoSlotsForTutor(tutorId: number, endDatetime: Date): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<DemoSlotStatus>>(
        `tutoring/get-demo-slots-for-tutor`,
        { params: { tutorId: tutorId, endDatetime: endDatetime } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
  async getTutorMonthlySalaryReport(tutorId: number, reportMonth: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutorMonthlySalaryDisplay>>(
        `tutoring/get-tutor-monthly-salary-report`,
        { params: { tutorId: tutorId, reportMonth: reportMonth } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateDemoSlotsForTutor(
    tutorId: number,
    slotStatuses: Array<DemoSlotStatus>,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `tutoring/update-demo-slots-for-tutor`,
        { tutorId: tutorId, slotStatuses: slotStatuses },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getDemoSessionsForAdmin(
    demoSessionStatusId: number,
    minDate: string,
    maxDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<DemoSessionDisplay>>(
        `tutoring/get-demo-sessions-for-admin`,
        {
          params: { demoSessionStatusId: demoSessionStatusId, minDate: minDate, maxDate: maxDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPaidSessionsForAdmin(
    categoryId: number,
    minDate: string,
    maxDate: string,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutorSessionDisplay>>(
        `tutoring/get-paid-sessions-for-admin`,
        {
          params: { categoryId: categoryId, minDate: minDate, maxDate: maxDate },
        },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async startPaidSession(tutorSessionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(`/tutoring/start-paid-session`, {
        tutorSessionId: tutorSessionId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async endPaidSession(tutorSessionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.put<boolean>(`/tutoring/end-paid-session`, {
        tutorSessionId: tutorSessionId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateTutorModule(updateTutorModuleInputs: TutoringModuleLessonPlan): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/tutoring/update-tutor-module`,
        updateTutorModuleInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async studentCancelPaidSession(tutorSessionId: number) {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `tutoring/student-cancel-paid-session`,
        { tutorSessionId: tutorSessionId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async tutorCancelPaidSession(
    tutorSessionId: number,
    cancellationReasonId: number,
  ): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `/tutoring/tutor-cancel-paid-session`,
        { tutorSessionId: tutorSessionId, cancellationReasonId: cancellationReasonId },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async cancelDemoSession(demoSessionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(`/tutoring/cancel-demo-session`, {
        demoSessionId: demoSessionId,
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getCourseProgressForStudent(studentId: number, courseId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<StudentCourseProgress>(
        `tutoring/get-course-progress-for-student`,
        { params: { studentId: studentId, courseId: courseId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getPaymentHistoryForStudent(studentId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<Array<TutoringStudentPaymentDisplay>>(
        `tutoring/get-payment-history-for-student`,
        { params: { studentId: studentId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async saveTutorSessionLog(tutorSessionLogInputs: TutorSessionLog): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `tutoring/save-tutor-session-log`,
        tutorSessionLogInputs,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getTutorSessionLog(tutorSessionId: number): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TutorSessionLog>(
        `tutoring/get-tutor-session-log`,
        { params: { tutorSessionId: tutorSessionId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async getStudentByEmail(emailId: string): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.get<TutoringStudentDetailedDisplay>(
        `tutoring/get-student-by-email`,
        { params: { emailId: emailId } },
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }

  async updateStudentData(updateStudentData: UpdateStudentDataInputs): Promise<ApiResponse> {
    try {
      const response = await this.axiosInstance.post<boolean>(
        `tutoring/update-student-data`,
        updateStudentData,
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
}
